import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import classes from './App.module.css';
import TestToken from '../src/abis/TestToken.json';
import Staking from './components/Staking';
import AdminTesting from './components/AdminTesting';
import swal from "sweetalert";

const directstakingABI = require("./abis/Directstaking.json");

const App = () => {
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  const Tokenaddress = process.env.REACT_APP_TOKEN_ADDRESS;
  const [account, setAccount] = useState('Connecting to Metamask..');
  const [network, setNetwork] = useState({ id: '0', name: 'none' });
  const [tokenStakingContract, setTokenStakingContract] = useState('');
  const [contractBalance, setContractBalance] = useState('0');
  const [totalStaked, setTotalStaked] = useState([0, 0]);
  const [myStake, setMyStake] = useState([0, 0]);
  const [appStatus, setAppStatus] = useState(true);
  const [loader, setLoader] = useState(false);
  const [userBalance, setUserBalance] = useState('0');
  const [apy, setApy] = useState([0, 0]);
  const [page, setPage] = useState(1);

  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [valuestate, setvaluestate] = useState(0);

  const [mystakee, setmystakee] = useState(0);
  const [myreward, setmyreward] = useState(0);
  const [mybalance, setmybalance] = useState(0);
  const [myrelesetme, setmyrelesetme] = useState(0);
  const [userapprove, setuserapprove] = useState(false);
  const [stakeContact, setStakeContact] = useState();
  const [userUnstakeAmount, setUserUnstakeAmount] = useState();
  const [tokenContract, setTokenContract] = useState();


  useEffect(() => {
    //connecting to ethereum blockchain
    const ethEnabled = async () => {
      fetchDataFromBlockchain();
    };
    
    ethEnabled();
    fetchDataFromBlockchain();
  }, []);


  // useEffect(() => {
  //   if (valuestate !== 0) {
  //     const init = async () => {
  //       await mystakeinfo(valuestate)
  //     };
  //     init();
  //   }
  // }, [valuestate]);        

  const mystakeinfo = async (val) => {
    setvaluestate(val)
  };
  
  const fetchDataFromBlockchain = async () => {
    if (window.ethereum) {
      // await window.ethereum.send('eth_requestAccounts');
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      window.web3 = new Web3(window.ethereum);

      //connecting to metamask
      let web3 = window.web3;
      const accounts = await web3.eth.getAccounts();
      setAccount(accounts[0]);

      const contract = new web3.eth.Contract(
        TestToken,
        Tokenaddress
      );
      setTokenContract(contract);
      const userbalance =  await contract.methods.balanceOf(accounts[0]).call({ from:accounts[0] });
      setmybalance(web3.utils.fromWei(userbalance,"ether"))
      const userallowance = await contract.methods.allowance(accounts[0], contractAddress).call({ from: accounts[0] });
      /** ADD the stacking contract */
      const directStakeContract = new web3.eth.Contract(
        directstakingABI,
        contractAddress
      );
      const unstakeAllowAmount = await directStakeContract.methods.userUnstakedAmount().call({from:accounts[0]})
      setUserUnstakeAmount(Web3.utils.fromWei(unstakeAllowAmount, "ether"));
      setStakeContact(directStakeContract);
      setvaluestate(0);
      const userDetails = await directStakeContract.methods.getUserDetails().call({ from: accounts[0] });
      setInfo(userDetails)
      if (userallowance > 0) {
        setuserapprove(true)
      } else {
        setuserapprove(false)
      }

      //removing loader
      setLoader(false);
    } else if (!window.web3) {
      setAppStatus(false);
      setAccount('Metamask is not detected');
      setLoader(false);
    }
  };

  const stake = async () => {
    if (amount !== 0) {
      try {
        const web3 = new Web3(window.web3.currentProvider);
        const userID = await web3.eth.getAccounts();
        const userWalletAddress = userID[0];
        const amountInWei = web3.utils.toWei(amount, 'ether');
        setLoading(true);
        if (userapprove == false) {
          const tokencontract = new web3.eth.Contract(
            TestToken,
            Tokenaddress
          );
          const approvecall = await tokencontract.methods.approve(contractAddress, amountInWei.toString()).send({ from: userWalletAddress }).then((res) => {
            if (res) {
              setuserapprove(false)
              setLoading(false);
              fetchDataFromBlockchain()
              swal("Success", "Approved successfully. Now, You can stake BTNT.");
            }
          })
        }
        else {
          const directStakeContract = new web3.eth.Contract(
            directstakingABI,
            contractAddress
          );
          const stakeBTNT = await directStakeContract.methods.stakeBTNT(amountInWei, valuestate).send({ from: userWalletAddress });
            if (stakeBTNT) {
              setAmount(0);
              fetchDataFromBlockchain();
              setLoading(false);
              swal("Success", `You have successfully deposit ${amount} BTNT`);
            }
            else {
              setLoading(false);
              swal("Oops!", "Could not write smart contract @@@@", "error");
            }
        }
      } catch (e) {
        setLoading(false);
        swal("Oops!", "Could not write smart contract !!!!", "error");
      }
    }
    else {
      swal("Oops!", "Please Enter Value", "error");
    }
  };

  const unstake = async () => {
    setLoading(true);
    let unstakeBTNT = await stakeContact.methods.userUnstake().send({ from: account });
      if (unstakeBTNT) {
        // mystakeinfo(valuestate);
        setLoading(false);
        fetchDataFromBlockchain()
        swal("Success", `You have successfully withdraw ${userUnstakeAmount} BTNT`);
      }
      else {
        setLoading(false);
        swal("Oops!", "Could not write smart contract", "error");
      }
  };

  const track = async (val) => {
    if (parseFloat(mybalance) >= val) {
      const userallowance = await tokenContract.methods.allowance(account, contractAddress).call({ from: account });
    if (parseFloat(Web3.utils.fromWei(userallowance, "ether")) >= val) {
      setuserapprove(true)
    } else {
      setuserapprove(false)
    }
    setAmount(val)
    } else {
      swal("Oops!", "You don't have enogh balance", "error");
    }
    
  };

  function timeConverter(UNIX_timestamp){
    if (UNIX_timestamp == 0) {
      var a = new Date();
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
      return time;
    } else {
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
    return time;
    }
  }

  const setInfo = async (res) => {
    setmystakee(Web3.utils.fromWei(res[1], "ether"))
    setmyreward(Web3.utils.fromWei(res[0], "ether"))
    setmyrelesetme(timeConverter(res[2]))
    // const userbalance = await stakeContact.methods.balance().call({ from: account });
    // setmybalance(Web3.utils.fromWei(userbalance, "ether"))
  };


  return (
    <div className={classes.Grid}>
      {loader ? <div className={classes.curtain}></div> : null}
      <div className={classes.loader}></div>
      <div className={classes.Child}>
        {/* <Navigation apy={apy} changePage={changePage} /> */}
        <div>
          <Staking
            account={account}
            totalStaked={page === 1 ? totalStaked[0] : totalStaked[1]}
            myStake={page === 1 ? myStake[0] : myStake[1]}
            userBalance={userBalance}
            stake={stake}
            unstake={unstake}
            setInfo={setInfo}
            mystakeinfo={mystakeinfo}
            mystakee={mystakee}
            myreward={myreward}
            mybalance={mybalance}
            myrelesetme={myrelesetme}
            contractBalance={contractBalance}
            userapprove={userapprove}
            amount={amount}
            userUnstakeAmount={userUnstakeAmount}
            track={track}
            loading={loading}
            apy={page === 1 ? apy[0] : apy[1]}
            page={page}
          />
        </div>
        {/* <div className={classes.for_testing}>
          <AdminTesting
            network={network}
            tokenStakingContract={tokenStakingContract}
            contractBalance={contractBalance}
            page={page}
          />
        </div> */}
      </div>
    </div>
  );
};

export default App;
