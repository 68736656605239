import React, { Component } from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class Loading extends React.Component {
  render() {
    return (
      <Loader
        type="TailSpin"
        color="#5e69f1"
        // color="#000"
        height={20}
        width={20}
        className="Loadingstyle"
      />
    );
  }
}

export default Loading;