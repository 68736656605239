import React from "react";
import classes from "./Staking.module.css";
import stakeIcon from "../assets/stake.png";
import unstakeIcon from "../assets/unstake.png";
import icon from "../assets/icon.png";
import Select from "react-select";
import Loader from "../loder";
import bitnautic from "../assets/bitnautic.png";

const Staking = (props) => {
  const options = [
    { value: 0, text: "Select Period", label: "Select Period" },
    { value: 0, text: "Lock for 90 Days", label: "Lock for 90 Days" },
    { value: 1, text: "Lock for 180 Days", label: "Lock for 180 Days" },
    { value: 2, text: "Lock for 270 Days", label: "Lock for 270 Days" },
    { value: 3, text: "Lock for 360 Days", label: "Lock for 360 Days" },
  ];

  return (
    <div className={classes.Staking}>
      <img src={bitnautic} alt="logo" className={classes.icon} />
      <h1>BitNautic Direct Staking</h1>
      <h3>Stake BTNT and Earn More BTNT</h3>
      <p>{props.account}</p>
      <div className={classes.inputselect}>
        <input
          type="text"
          placeholder="0"
          className={classes.inputt}
          value={props.amount}
          onChange={(e) => props.track(e.target.value)}
        />
        <Select
          className={classes.selectt}
          classNamePrefix="react-select"
          options={options}
          defaultValue={options[0]}
          disabled={options[0]}
          onChange={(e) => {
            console.log("e", e);
            props.mystakeinfo(e.value);
          }}
        />
      </div>

      {props.loading && (
        <Loader type="spinner-default" bgColor={"#FFFFFF"} size={25} />
      )}
      {!props.loading && (
        <>
          {/* <button
            className={classes.stakeButton}
            onClick={props.stake}
          >
            <img
              src={stakeIcon}
              alt="stake icon"
              className={classes.stakeIcon}
            />
            {props.userapprove ? <p>Lock</p> : <p>Approve</p>}
          </button> */}
          &nbsp; &nbsp;
          <button
            className={classes.unstakeButton}
            onClick={props.unstake}
            disabled={props.userUnstakeAmount == 0 ? true : false}
          >
            <img
              src={unstakeIcon}
              alt="unstake icon"
              className={classes.stakeIcon}
            />
            <p>Unlock</p>
          </button>
        </>
      )}

      <div className={classes.totals}>
        <h5>Wallet Balance: {props.mybalance} BTNT</h5>
        <h5>Staked Amount: {props.mystakee} BTNT </h5>
        <h5>Unstake Allow Amount: {props.userUnstakeAmount} BTNT</h5>
        <h5>Reward Amount: {props.myreward} BTNT</h5>
        <h5>Estimated Release time: {props.myrelesetme} </h5>
      </div>
    </div>
  );
};
export default Staking;
